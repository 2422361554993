import React, { useState, useMemo } from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Radio, RadioGroup } from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import _ from "lodash";
import InHouseCertificationsTable from "./InHouseCertificationsTable";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "transparent",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      backgroundColor: "#ffffff",
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const CustomRadio = withStyles({
  root: {
    color: "#DEE5EB",
    marginLeft: 10,
    padding: 0,
    "&$checked": {
      color: "#3246D3",
      padding: 0,
    },
    "& .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      backgroundColor: "white",
      zIndex: -1,
    },
  },
  checked: {},
})(Radio);

const CustomRadioLabel = withStyles({
  root: {
    gap: 8,
  },
})(FormControlLabel);

const PublicDesignationsForm = (props) => {
  const [userDetails, setUserDetails] = useState({ firstName: "", lastName: "", email: "" });
  const [inHouse, setInHouse] = useState(props.attestation.in_house);

  // TODO: Should this be consolidated into the pharmacies definition, so only one prop is passed to the InHouseCertifications table?
  const [attachments, setAttachments] = useState(
    props.attestation.in_house_certifications.map((certification) => ({
      npi: certification.npi,
      pharmacy_license: certification.has_license_attachment || null,
      additional_documents: certification.additional_attachments_filenames || [],
    }))
  );
  const [signature, setSignature] = useState("");

  const completedUserDetails = useMemo(() => {
    return !isInvalidEmail() && userDetails.email && userDetails.firstName && userDetails.lastName;
  }, [userDetails]);
  const answeredInHouseQuestion = inHouse !== null;
  const hasAttachedRequiredDocuments = useMemo(() => {
    if (props.attestation.documents_required && !inHouse) {
      return attachments.every((attachment) => attachment.pharmacy_license);
    }

    return true;
  });

  const hasSigned = signature.length;

  const [startedSubmission, setStartedSubmission] = useState(false);

  var invalidEmailDomains = ["gmail", "outlook", "yahoo", "hotmail"];

  function isInvalidEmail() {
    if (userDetails.email) {
      if (
        userDetails.email.includes("@") &&
        !_.map(invalidEmailDomains, (domain) => userDetails.email.includes(domain)).includes(true)
      ) {
        return false;
      } else {
        return true;
      }
    }
  }

  const enableSubmit = () => {
    return completedUserDetails && answeredInHouseQuestion && hasAttachedRequiredDocuments && hasSigned && !startedSubmission;
  };

  function handleSubmit(e) {
    e.preventDefault();

    setStartedSubmission(true);

    function createForm() {
      const form = new FormData();
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');

      form.append("token", token);
      form.append("user[first_name]", userDetails.firstName);
      form.append("user[last_name]", userDetails.lastName);
      form.append("user[email]", userDetails.email);
      form.append("in_house_attestation[id_340b]", props.attestation.id_340b);
      form.append("in_house_attestation[signed_by]", signature);
      form.append("in_house_attestation[in_house]", inHouse);

      attachments.forEach((attachment, index) => {
        form.append(`in_house_attestation[in_house_certifications][${index}][npi]`, attachment.npi);
        form.append(
          `in_house_attestation[in_house_certifications][${index}][pharmacy_license]`,
          attachment.pharmacy_license
        );

        if (attachment.additional_documents.length) {
          attachment.additional_documents.forEach((doc, subIndex) => {
            form.append(`in_house_attestation[in_house_certifications][${index}][additional_documents][${subIndex}]`, doc);
          });
        }
      });

      return form;
    }

    fetch(`/in_house_attestations`, {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: createForm()
    })
    .then(response => {
      if (response.redirected) {
        // If redirected, follow the new URL
        window.location.href = response.url;
      } else if (response.ok) {
        // Redirect to a confirmation page if no redirect was provided by the server
        window.location.href = "/in_house_attestations/confirmation";
      } else {
        // TODO: Handle validation errors gracefully
        console.error("Failed to submit form", response.statusText);
        setStartedSubmission(false);
      }
    })
    .catch(error => {
      setStartedSubmission(false);
      console.error("Error:", error);
    });
  }

  const renderBillboard = () => {
    return (
      <div className="register__billboard">
        <div className="register__billboard__title">Pharmacy Certifications</div>
        <div className="register__billboard__subtitle">All steps and fields are required *</div>
        <div className="register__billboard__step__container">
          <div
            className={
              completedUserDetails
                ? "register__billboard__step register__billboard__step--complete"
                : "register__billboard__step"
            }
          >
            1
          </div>
          <div className="register__billboard__step__details">
            <div className="register__billboard__step__details__title">Contact Details</div>
            <div className="register__billboard__step__details__subtitle">Provide your details</div>
          </div>
        </div>
        <div className="register__billboard__step__container">
          <div
            className={
              inHouse !== null
                ? "register__billboard__step register__billboard__step--complete"
                : "register__billboard__step"
            }
          >
            2
          </div>
          <div className="register__billboard__step__details">
            <div className="register__billboard__step__details__title">In-House Status</div>
            <div className="register__billboard__step__details__subtitle">Certify your entity's status</div>
          </div>
        </div>
        <div className="register__billboard__step__container">
          <div
            className={
              hasAttachedRequiredDocuments
                ? "register__billboard__step register__billboard__step--complete"
                : "register__billboard__step"
            }
          >
            3
          </div>
          <div className="register__billboard__step__details">
            <div className="register__billboard__step__details__title">Documentation</div>
            <div className="register__billboard__step__details__subtitle">Attach documents if requested</div>
          </div>
        </div>
        <div className="register__billboard__step__container">
          <div
            className={
              !!signature
                ? "register__billboard__step register__billboard__step--complete"
                : "register__billboard__step"
            }
          >
            4
          </div>
          <div className="register__billboard__step__details">
            <div className="register__billboard__step__details__title">Signature</div>
            <div className="register__billboard__step__details__subtitle">Sign and attest to submit</div>
          </div>
        </div>
        <button
          className={enableSubmit() ? "btn" : "btn btn--disabled"}
          disabled={enableSubmit() ? false : true}
          type="submit"
        >
          Submit
        </button>
        <div className="register__billboard__footernote">
          Need Help? Contact Us
          <div className="register__billboard__footernote__contact">support@340besp.com</div>
        </div>
      </div>
    );
  };

  const handleRadioChange = (e) => {
    const val = e.target.value;

    if (val == "true") {
      setInHouse(true);
    } else if (val == "false") {
      setInHouse(false);
    }
  };

  function renderContactDetails() {
    return (
      <div>
        <div className="register__step__title">1. Enter Contact Details</div>
        <div className="register__form register__form--large">
          <div className="register__form__dual-row register__form__dual-row--pad-right">
            <CustomTextField
              label="First Name"
              margin="normal"
              variant="outlined"
              id="firstName"
              name="user[first_name]"
              autoFocus
              fullWidth
              required
              onChange={(event) => {
                setUserDetails({
                  ...userDetails,
                  firstName: event.target.value,
                });
              }}
            />
          </div>
          <div className="register__form__dual-row register__form__dual-row--pad-left">
            <CustomTextField
              label="Last Name"
              margin="normal"
              variant="outlined"
              id="lastName"
              name="user[last_name]"
              fullWidth
              required
              onChange={(event) => {
                setUserDetails({
                  ...userDetails,
                  lastName: event.target.value,
                });
              }}
            />
          </div>
          <div className="register__form__requirements">
            <p>
              <span className="bold">Note: </span>
              Please provide your
              <span className="bold"> Work Email Address.</span>
            </p>
          </div>
          <CustomTextField
            label="Work Email Address"
            margin="normal"
            variant="outlined"
            id="email"
            name="user[email]"
            type="email"
            fullWidth
            required
            error={isInvalidEmail()}
            helperText={
              userDetails.email && isInvalidEmail()
                ? "Email is not valid - Please provide a valid Work Email Address for this covered entity."
                : null
            }
            onChange={(event) => {
              setUserDetails({
                ...userDetails,
                email: event.target.value,
              });
            }}
          />
        </div>
      </div>
    );
  }

  function renderInHouseQuestion() {
    return (
      <div>
        <div className="register__step__title">2. Certify In-House Status</div>
        <div className="register__form register__form--large">
          <div>
            <p className="t--500">
              Does {props.attestation.id_340b} have an in-house pharmacy capable of retail dispensing for a patient's subsequent
              self-administration?
            </p>
            <p>
              To proceed, select <span className="t--500">Yes</span> or <span className="t--500">No</span>
            </p>
            <div id="in_house_response">
              <RadioGroup
                name="in_house_attestation[in_house]"
                row
                color="primary"
                value={inHouse}
                onChange={handleRadioChange}
              >
                <CustomRadioLabel
                  control={
                    <CustomRadio
                      checked={inHouse == true}
                      value="true"
                      icon={<CheckBoxOutlineBlank />}
                      checkedIcon={<CheckBox />}
                    />
                  }
                  label="Yes"
                />
                <CustomRadioLabel
                  control={
                    <CustomRadio
                      checked={inHouse == false}
                      value="false"
                      icon={<CheckBoxOutlineBlank />}
                      checkedIcon={<CheckBox />}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderDesignationWarning() {
    if (inHouse == true) {
      return (
        <div className="t--title mb--16">
          <div className="t--500 mb--8">Attention: Existing Designations May Be Removed</div>
          <div className="pharm_cert_form__designations_warning t--sm br--4 pa--12 gap--8">
            You have indicated that {props.attestation.id_340b} has an in-house pharmacy. Existing designations may be
            removed depending on the criteria of any pharmaceutical manufacturer's policy.
          </div>
        </div>
      );
    }

    return null;
  }

  function renderAdditionalDocumentsRequest() {
    if (inHouse == null) {
      return (
        <div>
          <div>Complete step 2 to continue</div>
        </div>
      );
    }

    if (props.attestation.documents_required == true && inHouse == false) {
      return (
        <>
          <div>
            <div className="t--500 t--16 mb--8">Provide the Pharmacy License</div>
            <div className="t--14">
              Please provide a pharmacy license for each listed pharmacy. A license must be provided for each pharmacy
              to submit. You will not be able to save your progress. Instead, revisit this page when you have all
              documentation available.
            </div>
          </div>
          <InHouseCertificationsTable
            pharmacies={props.attestation.in_house_certifications}
            attachments={attachments}
            setAttachments={setAttachments}
          />
        </>
      );
    }

    return (
      <div>
        {renderDesignationWarning()}
        <div className="t--500 t--16 mb--8">No Additional Documentation Requests</div>
        <div>Provide you signature below to submit.</div>
      </div>
    );
  }

  function renderUploadSection() {
    return (
      <div>
        <div className="register__step__title">3. Attach Requested Documentation</div>
        <div className="register__form register__form--large">
          <div className="pharm_cert_form__entity">
            <div className="pharm_cert_form__entity_banner">
              <div className="bg--border br--8 pa--16 t--500 pharm_cert_form__entity_type_code pharm_cert_form__circle">{props.attestation.entity_type_code}</div>
              <div className="bg--border br--8 pa--16 t--500">{props.attestation.id_340b}</div>
            </div>
            {renderAdditionalDocumentsRequest()}
          </div>
        </div>
      </div>
    );
  }

  function renderSignAndAttest() {
    return (
      <div>
        <div className="register__step__title">4. Sign and Attest</div>
        <div className="register__form register__form--large">
          <CustomTextField
            label="Your name here"
            margin="normal"
            variant="outlined"
            id="signed_by"
            name="in_house_attestation[signed_by]"
            fullWidth
            required
            onChange={(event) => {
              setSignature(event.target.value);
            }}
          />
        </div>
      </div>
    );
  }

  function renderHiddenInputs() {
    return (
      <>
        <input
          type="hidden"
          name="authenticity_token"
          value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")}
        />
        <input type="hidden" name="in_house_attestation[id_340b]" value={props.attestation.id_340b} />
        <input type="hidden" name="id_340b" value={props.attestation.id_340b} />
      </>
    );
  }
  return (
    <div className="register__wrapper register__wrapper--large">
      <form method="POST" action="/in_house_attestations" onSubmit={handleSubmit} id="in_house_attestation_form">
        <div className="register__form__container register__form__container--large">
          {renderContactDetails()}
          {renderInHouseQuestion()}
          {renderUploadSection()}
          {renderSignAndAttest()}
          {renderHiddenInputs()}
        </div>
        {renderBillboard()}
      </form>
    </div>
  );
};

export default PublicDesignationsForm;
