import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import AlertModal from "../AlertModal";
import ProgressBar from "./components/ProgressBar";

const ActionRequired = (props) => {
  const contentCopy = {
    submitCertification: {
      title: "Submit the Certification",
      description: `Pharmacy Certification requires users certify the current in-house status of their covered entity. Submit your covered entity’s Pharmacy Certification by ${props.stats?.due_at} to ensure access to pricing is not interrupted.`,
    },
    viewCertificationSummary: {
      title: "Certification Summary",
      description: "View the status of your covered entity's submitted Pharmacy Certification. For questions, contact 340B ESP Support."
    }
  };

  const hasStartedProcess = parseInt(props.stats?.percentage_completed) > 0;

  const { title, description } = (() => {
    if (hasStartedProcess) {
      return contentCopy.viewCertificationSummary;
    } else {
      return contentCopy.submitCertification;
    }
  })();

  function daysLeftText() {
    const daysLeft = props.stats?.days_left > 0 ? props.stats?.days_left : 0;
    const dayText = props.stats?.days_left === 1 ? "day" : "days";

    return `${daysLeft} ${dayText} left to submit`;
  }

  function renderReminderModalTitle() {
    return (
      <>
        <div>Pharmacy Certification</div>
        <div>{props.stats.days_left} Days Remaining to Complete</div>
      </>
    );
  }

  function renderButtonText() {
    if (hasStartedProcess) {
      return "Review";
    }

    return "Start";
  }

  return (
    <>
      <div className="action_required__container">
        <div className="action_required__contents">
          <div className="flex--col gap--8">
            <div className="action_required__tag action_required__tag--pulse">Action Required</div>
            <div className="t--700">{title}</div>
            <div className="t--sm t--subtitle">{description}</div>
          </div>
          <div className="action_required__stats-container" style={{ gap: 0 }}>
            <div className="t--md t--500 mb--4">{props.stats?.entities_remaining} CEs Remaining</div>
            <div className="t--sm t--subtitle mb--8">{daysLeftText()}</div>
            <div className="flex align-center" style={{ gap: 6 }}>
              <span className="t--700" style={{ fontSize: 18 }}>
                {props.stats?.percentage_completed}%
              </span>
              <ProgressBar percent={`${props.stats?.percentage_completed}%`} color="green" />
            </div>
          </div>
          <button
            className="action_required__start-button bg--blue t--md t--white t--500 br--8"
            onClick={() => props.setDisplayModal(true)}
          >
            {renderButtonText()}
          </button>
        </div>
      </div>
    </>
  );
};

export default ActionRequired;
